import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Link from '../components/Link'
import { One, Row, Three, Two } from '../components/Grid'
import SEO from '../components/SEO'
import { H2, H4, Section, SEOH1, SmallText } from '../components/Style'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/faq",
      "name": "FAQ"
    }
  }]
}`

export default () => (
  <Layout>
    <Fragment>
      <SEO
        path="/faq"
        title="Frequently Asked Questions"
        description="A list of common questions many clients ask us before working together"
        ld={breadcrumbs}
      />

      <Section>
        <Row>
          <SEOH1>Common Questions</SEOH1>

          <Three>
            We have compiled a list of questions that most clients ask us before deciding whether or not we are a good
            fit for each other. We are always happy to meet and discuss your specific requirements and answer more
            questions.
          </Three>
        </Row>
      </Section>

      <Row>
        <One>
          <H2>Our Services</H2>

          <SmallText>
            Our expertise is developing complex single-page applications. If you are looking for design, devops, or
            maintenance services we would be happy to connect you with one of our partners.
          </SmallText>
        </One>

        <Three>
          <Row>
            <Two>
              <QuestionBlock>
                <Question>How can you help me?</Question>
                We architect and develop new web applications, join existing development teams remotely, do remote and
                on-site consulting, and teach front-end.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you work on a fixed-cost project?</Question>
                No. Fixed-cost project are conflict of interests: if it takes more time than planned you get the bare
                minimum, if it takes less you overpay. It is important to be able to adjust quickly and think outside of
                the box; detailed fixed specs don't allow that. If the budget is fixed the scope should be flexible.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you have back-end or full-stack developers?</Question>
                We are focused on front-end technologies and have expertise in client-side applications. We do have
                people that can help with NodeJS backend in some special cases. It is something we can discuss on a
                call.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you build mobile apps?</Question>
                No, neither native (Swift, Java, Kotlin) nor hybrid (React Native, Cordova, Ionic). We specialize in web
                platform and would be able to help with adapting a web application to mobile web or PWA.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you build a WordPress website?</Question>
                No. We develop interactive single-page applications with modern mainstream frameworks based on
                JavaScript/TypeScript. We can connect you with our partner that can provide such services.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you help me interview front-end candidates?</Question>
                Not as a standalone service. In some cases we help existing development clients onboard new developers
                to projects we have participated in by preparing and holding interviews and training their new hires.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you join an existing project?</Question>
                Absolutely. As long as you are fine with remote team extension (all our people work from one office) we
                can work either as a managed workforce or can lead the planning and managing of the front end along
                development.
              </QuestionBlock>
            </Two>

            <Two>
              <QuestionBlock>
                <Question>Have you worked with a company similar to ours?</Question>
                Over the years we have helped over 200 companies from different industries. Most likely that we have
                worked with a company like yours in the past. Check our <Link to="/clients">clients page</Link> to see
                for yourself.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you build an MVP?</Question>
                Yes. However take into account that building an MVP usually does not require expensive professionals.
                Many times it is better to hire cheaper engineers and rewrite the MVP once the product is validated and
                you are ready to build for the long term. Give us a call to discuss this further.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you design apps?</Question>
                No. We can introduce you to design companies we have successfully worked with in the past. During the
                development we provide UX insights, but it is not a substitution for a professional designer.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you write technical specs?</Question>
                No. In case of front-end applications, fixed technical specifications are counterproductive. It is
                better to provide a visual representation together with short explanations and work closely with the
                developer and the designer as requirements tend to change frequently.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you help with DevOps?</Question>
                We can have a technical discussion about different solutions, but usually leave the technical
                implementation and configuration to DevOps specialists.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you help me choose a technology?</Question>
                Yes, as long as it is in our area of expertise. Let's talk; if we feel that we are pushing the
                boundaries of our knowledge we will let you know immediately.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you maintain projects after initial work is done?</Question>
                No. Our goal is to make sure our clients have enough expertise to keep developing, supporting, and
                maintaining their projects on their own. We are happy to help with further development and
                consulting, but we don't look to stay in projects longer than necessary.
              </QuestionBlock>
            </Two>
          </Row>
        </Three>
      </Row>

      <Row>
        <One>
          <H2>Work Process</H2>

          <SmallText>
            We keep our work process as transparent and effortless as possible and adjust to your needs and existing
            practices. We transfer the expertise to clients as soon as possible and don't overstay our welcome.
          </SmallText>
        </One>

        <Three>
          <Row>
            <Two><QuestionBlock>
              <Question>Can your developers sit in our office?</Question>
              Not on a regular basis. We work as a team which is important for our people to feel a part of the company
              and for our clients, as they get the expertise of the entire company instead of a one person. We can visit
              once in a while for important meetings, integrations, or discussions.
            </QuestionBlock>

              <QuestionBlock>
                <Question>How do you ensure QA?</Question>
                We check the functionality during development and have internal code review policies to ensure code
                quality. Provided enough budget, we can write automated testing (this usually doubles the development
                time). We don't have a dedicated QA team for manual or automated testing.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you work on weekends or late evenings?</Question>
                No. We make effort to foresee unrealistic estimations and help mitigate emergencies before overtime work
                is necessary. We always advise not to push to production on Thursdays and make sure there are reliable
                rollback mechanisms for reverting changes.
              </QuestionBlock>

              <QuestionBlock>
                <Question>How flexible are you with time zones?</Question>
                Depending on the project and the team, we can get around 5 hours of overlap with US East Coast and about
                2-3 hours of overlap with the US West Coast.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Are you comfortable working with Jira, Asana, Trello, etc?</Question>
                Yes. Every company has its own set of tools and practices, and we've seen most of them. The esoteric
                ones we haven't seen yet we will learn quickly.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you follow agile working practices?</Question>
                We have our opinions on many of the practices defined in the Agile manifesto, but we would be happy to
                adapt to any working processes that are conventional in your company.
              </QuestionBlock></Two>

            <Two>
              <QuestionBlock>
                <Question>Do you attend daily or weekly meetings?</Question>
                All our clients have different development practices and cycles and we are happy to join and adapt to
                any existing schedule and cadence.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you visit our office?</Question>
                Most consultations and trainings are done on-site, preferably in your office. During development we can
                visit when necessary.
              </QuestionBlock>

              <QuestionBlock>
                <Question>How do we start?</Question>
                After the contract is signed and people are assigned, we schedule a kick-off session to learn as
                much as possible about the codebase, requirements, and most importantly about your business. If this is
                an existing project we then take on smaller tasks that allow us to get the hands dirty and soon as
                possible. In case of a new project we develop a working plan and start working.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you work with an existing team?</Question>
                Yes. We can work either as a managed workforce or can lead the planning and managing of the front end
                along development.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you work with a remote team?</Question>
                Absolutely. Our development is done remotely from client's perspective anyway, there is little
                difference if your team is sitting in your office or is spread around. In case of timezone differences
                we usually find ways to communicate directly and efficiently.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you do automated testing?</Question>
                Yes, according to budget and requirements. Usually automated testing doubles the development time.
                All tests are written by the developers working on the project, not a dedicated QA team.
              </QuestionBlock>
            </Two>
          </Row>
        </Three>
      </Row>

      <Row>
        <One>
          <H2>Legal and Finance</H2>

          <SmallText>
            In case there are any inconsistencies between what's written in this FAQ and the actual contract,
            a) please let us know and b) the contract takes precedence.
          </SmallText>
        </One>

        <Three>

          <Row>
            <Two>
              <QuestionBlock>
                <Question>Can you sign an NDA?</Question>
                Yes, as long as it includes industry-standard clauses and only after we understand the overall
                requirement, type of project, technologies, and are sure that the project is within our area of
                expertise. As a consultancy we prefer signing a Mutual NDA.
              </QuestionBlock>

              <QuestionBlock>
                <Question>What is your cancellation policy?</Question>
                Five business days notice in the first two weeks, then twenty business days notice for development and
                consulting. For training: up to seven days before the training starts with 50% penalty.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you accept Net+90?</Question>
                No. Our payment terms are Net+30, with very rare exceptions of Net+60.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can you use our agreement?</Question>
                In some cases, and only as long as it includes important clauses such as non-solicitation, doesn't
                restrict our work with other clients, and has generally similar terms as in our standard agreement.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can we work on an hourly basis?</Question>
                Most consulting is done on an hourly basis. For development services we prefer daily (shorter
                term) or monthly (longer term) arrangements.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can we get a discount?</Question>
                In rare cases we could consider a discount based on contractual time commitment, flexibility in work
                process, or learning opportunity.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you charge for holidays?</Question>
                With daily pricing arrangement we only charge days actually worked. With monthly arrangement the client
                pays for all government holidays as well as one sick day and one vacation day per month (up to a certain
                limit).
              </QuestionBlock>
            </Two>

            <Two>
              <QuestionBlock>
                <Question>How much does it cost to develop a project?</Question>
                Anywhere between a cost of a new car and a fleet of new cars. We can roughly estimate a project based on
                a visual wireframe, but functionality inevitably changes. We advise checking what is possible to achieve
                under your time and budget constraints and develop a flexible working plan to deploy a minimal viable
                product and enhance it over time.
              </QuestionBlock>

              <QuestionBlock>
                <Question>How much does training cost?</Question>
                9,500 ILS or $3,000 per day of training including applicable taxes. Reach out for special pricing for
                large and long term arrangements. For custom curriculums, add additional half day of work per custom day
                of training.
              </QuestionBlock>

              <QuestionBlock>
                <Question>How much does consulting cost?</Question>
                Ad-hoc hourly consulting, prepaid hour banks, and ongoing technical support are priced differently based
                on scope and requirements. Ad-hoc on-demand consulting is the most expensive type, ongoing consultations
                and hour banks of 24 hours and more are subject to discounts.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can we buy a bank of consulting hours?</Question>
                Yes, you can pay in advance to lock a specific amount of consulting hours. You can get a slight discount
                when ordering 24 hours or more.
              </QuestionBlock>

              <QuestionBlock>
                <Question>What are your payment terms?</Question>
                We bill once a month for the work done, except hour banks and some trainings which are paid in advance.
                We only accept wire transfers. Our payment terms are Net+30. Tax invoices are sent within five business
                days after the payment has been received.
              </QuestionBlock>
            </Two>
          </Row>
        </Three>
      </Row>

      <Row>
        <One>
          <H2>Workforce</H2>

          <SmallText>
            Our vision is to have a small but fast, experienced, caring, and accountable team of experts and charge
            clients for expertise and not for the overhead.
          </SmallText>
        </One>

        <Three>
          <Row>
            <Two>
              <QuestionBlock>
                <Question>Who will be working on my project?</Question>
                We can't tell until we understand the type of the project, its time frames and requirements. Once we
                have this information, we will know you who can be available to work with you.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can we hire your people?</Question>
                No. All our agreements include a strict non-solicitation clause.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Who will be managing the project?</Question>
                We do not have a dedicated project management layer. All our developers are in direct communication with
                the client and accountable for their success. In any case an experienced person (architect level) joins
                all projects for oversight.
              </QuestionBlock>
            </Two>

            <Two>
              <QuestionBlock>
                <Question>Can I speak to your developers before signing?</Question>
                Yes, as means of confirmation and not negotiation. This means only after we have discussed the
                requirements, time frames, know which people would be the best fit and you don't have any other
                obstacles for signing a contract.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Who will be my point of contact?</Question>
                The people doing the development, training, or consulting. CEO and CTO are always available in case you
                have any problems or questions.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Do you hire developers outside of Israel?</Question>
                No. All our people are local, paid employees of the company, sitting in one office. We do not outsource
                any work offshore or to freelancers.
              </QuestionBlock>
            </Two>
          </Row>
        </Three>
      </Row>

      <Row>
        <One>
          <H2>Training</H2>

          <SmallText>
            Our courses and workshops are built and taught by our engineers. Our materials are based on experience
            of building dozens of applications and consulting to companies in diverse sectors.
          </SmallText>
        </One>

        <Three>
          <Row>
            <Two>
              <QuestionBlock>
                <Question>What can you teach us?</Question>
                We have a set of standard courses and workshops for various technologies. Check out our <Link
                to="/training">training section</Link> to learn more and check syllabuses. We can also develop custom
                training for your team based on your requirements.
              </QuestionBlock>

              <QuestionBlock>
                <Question>Can I send 2-3 people to your training?</Question>
                Rarely. We usually host trainings for groups of at least 7 people. If you don't have another team to
                join you, call us to check if there are any groups that we might combine together.
              </QuestionBlock>

              <QuestionBlock>
                <Question>How many people can participate in your training?</Question>
                Up to 12 people when using our facilities, up to 20 people in your classroom.
              </QuestionBlock>
            </Two>

            <Two>
              <QuestionBlock>
                <Question>Can you develop a custom workshop?</Question>
                Yes, as long as the topics are within our area of expertise. Preparing custom materials add half-day for
                each day of requested training. For example if you want a custom 3-day course, we would charge for
                additional 1.5 days of preparation.
              </QuestionBlock>

              <QuestionBlock>
                <Question>What is required to participate?</Question>
                Each attendee should have their own laptop with some pre-installed software. All
                pre-requisites are sent in advance before the training.
              </QuestionBlock>
            </Two>
          </Row>
        </Three>
      </Row>

      <ContactForm/>
    </Fragment>
  </Layout>
)

const QuestionBlock = styled.div`
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 20px;
`

const Question = styled(H4)`
  margin-bottom: 6px;
`
