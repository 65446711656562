const listeners = []

export default class {
  static emit(eventType, value) {
    listeners.forEach(fn => fn(eventType, value))
  }

  static subscribe(fn) {
    listeners.push(fn)

    return () => listeners.filter(listener => listener !== fn)
  }

  static listeners() {
    return listeners
  }
}
