import React from 'react'
import styled from 'react-emotion'
import Button from './Button'
import {
  BRAND_COLOR,
  COLOR,
  CONTRAST_COLOR,
  FONT_FAMILY,
  FONT_SMALL,
  PANEL_COLOR,
} from '../constants'
import EventEmitter from '../EventEmitter'
import Link from './Link'

// const encode = data => {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

export default class extends React.Component {
  state = {
    state: 'ready',
  }

  componentDidMount() {
    this.unsubscribe = EventEmitter.subscribe((eventType, value) => {
      if (eventType === 'prefillMessage') {
        this.getMessage().value = value
        this.getMessage().scrollIntoView({ behavior: 'smooth' })
        this.getMessage().focus()
      }
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  getName() {
    return document.getElementById('name');
  }

  getMessage() {
    return document.getElementById('message');
  }

  getReplyTo() {
    return document.getElementById('reply-to');
  }

  sendMessage = (event) => {
    event.preventDefault()

    if (!this.getReplyTo().value) {
      this.getReplyTo().style.setProperty('border-color', BRAND_COLOR)
    }

    if (!this.getName().value) {
      this.getName().style.setProperty('border-color', BRAND_COLOR)
    }

    if (!this.getName().value || !this.getReplyTo().value || !this.getMessage().value) {
      this.setState({ state: 'failure' })
      return
    }

    const textLength = this.getMessage().value.length
    const areaWidth = this.getMessage().offsetWidth

    this.getMessage().rows = Math.min(
      4,
      Math.ceil(textLength * 9.6 / areaWidth)
    )

    // fetch('/', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //   body: encode({
    //     'form-name': 'reachOut',
    //     message: this.getMessage().value,
    //     replyTo: this.getReplyTo().value,
    //   }),
    // })
    //   .then(() => this.setState({ state: 'success' }))
    //   .catch(() => this.setState({ state: 'failure' }))

    fetch('/.netlify/functions/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: this.getName().value,
        message: this.getMessage().value,
        replyTo: this.getReplyTo().value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({ state: 'success' })
        } else {
          this.setState({ state: 'failure' })
        }
      })
      .catch(() => this.setState({ state: 'failure' }))

    this.setState({ state: 'sending' })
  }

  resetMessage = event => {
    event.preventDefault()

    this.getName().value = ''

    this.getReplyTo().value = ''

    this.getMessage().value = ''
    this.getMessage().rows = 4
    this.getMessage().focus()

    this.setState({ state: 'ready' })
  }

  getSubmitButtonValue = () => {
    switch (this.state.state) {
      case 'failure':
        return 'Try again'
      case 'success':
        return 'Sent'
      case 'sending':
        return 'Sending...'
      default:
        return 'Send'
    }
  }

  render() {
    const isDisabled =
      this.state.state === 'sending' || this.state.state === 'success'

    return (
      <Container>
        <ContactWrapper>
          <form
            name="reachOut"
            noValidate
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.sendMessage}>
            <input type="hidden" name="form-name" value="reachOut" />

            <Title>Reach out</Title>

            <Field>
              <Label htmlFor="name" style={{ marginTop: 1 }}>
                <nobr>Name</nobr>
              </Label>
              <TextInput
                sending={isDisabled}
                disabled={isDisabled}
                defaultValue=""
                name="name"
                id="name"
                type="text"
              />
            </Field>

            <Field>
              <Label htmlFor="message" style={{ marginTop: 15 }}>
                Your message
              </Label>
              <Textarea
                sending={isDisabled}
                disabled={isDisabled}
                defaultValue=""
                id="message"
                name="message"
                rows="4"
              />
            </Field>

            <Field>
              <Label htmlFor="reply-to" style={{ marginTop: 1 }}>
                <nobr>Email or phone</nobr>
              </Label>
              <TextInput
                sending={isDisabled}
                disabled={isDisabled}
                defaultValue=""
                name="replyTo"
                id="reply-to"
                type="text"
              />
            </Field>

            <SubmitButton
              disabled={isDisabled}
              type="submit"
              id="sendMessage"
              value={this.getSubmitButtonValue()}
            />
            <SubmitLabel>We reply within 24h</SubmitLabel>
          </form>

          {this.state.state === 'success' && (
            <Field onClick={this.resetMessage}>
              <Link white className="smaller">
                Send another message
              </Link>
            </Field>
          )}
        </ContactWrapper>
      </Container>
    )
  }
}

const Container = styled.div`
  border-radius: 6px;
  background-color: ${PANEL_COLOR};
  padding: 60px 20px;

  @media only screen and (max-width: 1023px) {
    padding: 40px 20px;
  }
`

const ContactWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 28px;
  color: ${CONTRAST_COLOR};
  font-weight: 400;
  margin-bottom: 20px;
`

const Field = styled.div`
  position: relative;
  margin-bottom: 40px;
`

const Label = styled.label`
  position: absolute;
  right: calc(100% + 20px);
  width: 140px;
  font-size: 20px;
  text-align: right;

  @media only screen and (max-width: 1023px) {
    position: initial;
    right: 0;
  }
`

const Textarea = styled.textarea`
  outline: 0;
  border: none;
  color: ${props => (props.sending ? COLOR : CONTRAST_COLOR)};
  background: ${props =>
    props.sending
      ? 'transparent'
      : '#333 repeating-linear-gradient(#333, #333 39px, #565050 39px, #565050 40px, #333 40px) local'};
  line-height: 40px;
  padding: 8px 0;
  width: 100%;
  resize: none;
  font-size: 20px;
  font-weight: 200;
  font-family: ${FONT_FAMILY};
`

const TextInput = styled.input`
  outline: 0;
  border: none;
  color: ${props => (props.sending ? COLOR : CONTRAST_COLOR)};
  border-bottom: 1px solid #565050;
  border-color: ${props => (props.sending ? 'transparent' : '#565050')};
  background-color: ${PANEL_COLOR};
  width: 100%;
  font-size: 20px;
  font-weight: 200;
  font-family: ${FONT_FAMILY};
`

const SubmitButton = styled(Button.withComponent('input'))`
  padding-right: 160px;
  font-size: 32px;
`

const SubmitLabel = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
  width: 80px;
  color: ${CONTRAST_COLOR};
  line-height: 1.4;
  margin-left: 10px;
  font: ${FONT_SMALL};

  @media only screen and (max-width: 1023px) {
    display: block;
    width: 100%;
    margin: 10px 0 0 0;
  }
`
