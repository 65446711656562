import React from 'react'
import Helmet from 'react-helmet'

export default ({ title, description, mainImage, path, ld }) => {
    const image = mainImage || '/assets/images/500-logo-open-graph.jpg';

    return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>

      <meta property="og:url" content={`https://500tech.com${path}`}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>

      <meta property="og:image" content={image}/>
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:alt" content="500Tech" />
      {ld && <script type="application/ld+json">{ld}</script>}
    </Helmet>
  )
}
