import styled from 'react-emotion'
import { CONTRAST_COLOR, FONT_BIG } from '../constants'

export default styled.div`
  display: inline-block;
  background-color: #565050;
  color: ${CONTRAST_COLOR};
  border: none;
  border-radius: 4px;
  padding: 8px 48px 8px 12px;
  font: ${FONT_BIG};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${props => (props.disabled ? '#565050' : '#666060')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`
